@font-face {
  font-family: 'AvenirHeavy';
  src: local('MyFont'), url(./assets/fonts/Avenir-Heavy.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirLight';
  src: local('AvenirLight'),
    url(./assets/fonts/Avenir-Light.ttf) format('truetype');
}
@font-face {
  font-family: 'AvenirMedium';
  src: local('AvenirMedium'),
    url(./assets/fonts/Avenir-Medium.ttf) format('truetype');
}

:root {
  --font-family-sans-serif: 'AvenirMedium' !important;
  --font-family-monospace: 'AvenirMedium' !important;
  --blue: #1d3246 !important;
  --white: #ffffff !important;
  --black: #000000 !important;
  --active-blue: #427da5 !important;
}

body {
  margin: 0;
  background-color: var(--black) !important;
  font-family: 'AvenirMedium' !important;
  color: var(--white) !important;
  /* -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
}

html {
  /* background-color: black !important; */
  font-family: 'AvenirMedium' !important;
}

.swiss .swiss-navbar .bg-black {
  background-color: var(--blue) !important;
  padding: 0.5rem 5.5rem;
}

.swiss .swiss-navbar .navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0'%3F%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' version='1.1' width='512' height='512' x='0' y='0' viewBox='0 0 512 512' style='enable-background:new 0 0 512 512' xml:space='preserve' class=''%3E%3Cg%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3Cg%3E%3Cpath d='M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20 C512,404.954,503.046,396,492,396z' fill='%23ffffff' data-original='%23000000' style='' class=''/%3E%3C/g%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3Cg xmlns='http://www.w3.org/2000/svg'%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
}

.swiss .swiss-navbar .navbar .navbar-collapse {
  padding-left: 100px;
}

.swiss .swiss-navbar .navbar-nav .nav-link {
  color: var(--white);
  font-weight: 600;
  margin-right: 75px;
  position: relative;
  padding-left: 0;
  padding-right: 0;
  transition: 0.3s;
}

.swiss .swiss-navbar .navbar-nav .nav-link:focus {
  color: var(--blue) !important;
}

.swiss .swiss-navbar .navbar-nav .nav-link:hover {
  color: var(--white) !important;
}

.swiss .swiss-navbar .navbar-nav .nav-link:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 5px;
  left: 0px;
  background-color: var(--blue);
  visibility: hidden;
  width: 0px;
  transition: all 0.3s ease-in-out 0s;
}

.swiss .swiss-navbar .navbar-nav .nav-link:hover:before {
  visibility: visible;
  width: 100%;
}

.swiss .swiss-navbar a {
  color: var(--white);
}

.customSwiper .swiper-button-prev,
.customSwiper .swiper-button-next {
  color: var(--white) !important;
}
.customSwiper .swiper-pagination-bullet-active {
  background-color: var(--white) !important;
}
.customSwiper .swiper-pagination-bullet {
  background-color: var(--white) !important;
}

.customSwiper .swiper {
  width: 100%;
  height: 100%;
  background: var(--white);
}

.customSwiper .swiper-slide {
  font-size: 18px;
  color: var(--white);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* padding: 40px 60px; */
}

.customSwiper .parallax-bg {
  /* position: absolute; */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
}

.customSwiper .swiper-slide .title {
  font-size: 41px;
  font-weight: 300;
  max-width: 600px;
  width: 100%;
}

.customSwiper .swiper-slide .subtitle {
  font-size: 21px;
}

.customSwiper .swiper-slide .text {
  font-size: 14px;
  max-width: 600px;
  width: 100%;
  line-height: 1.3;
}

.courseDetailBanner {
  min-height: 40vh;
  background-size: cover;
  /* background-position: center; */

  /* background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)); */
  /* url('https://swiss-app-media.s3.amazonaws.com/course-images/c01f5c90-f0f6-4718-900b-d5483a25a328.jpg'); */
}

.bg-Img {
  background-image: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75),
      rgba(0, 0, 0, 0.2)
    ),
    url('../src/assets/images/Banner-01.jpg');
}

.thumbnailShadow {
  box-shadow: 0 0.5rem 1rem rgba(255, 255, 255, 0.15);
}

#imgHover:hover {
  transform: scale(1.035);
  box-shadow: 0 0 1rem rgb(255 255 255 / 15%);
}

#imgHover {
  transition: 0.5s ease;
  cursor: pointer;
  border-radius: 1rem;
  height: 400px;
  /* object-fit: cover; */
}

.stream img {
  height: 300px;
}

.customSwiper img {
  height: 350px;
}

.swiss .support .support-info,
.swiss .privacy .privacy-info,
.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: 400px;
  background-color: #fff;
  position: relative;
}

.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: auto;
}

.swiss .support .support-info .info-form,
.swiss .privacy .privacy-info .info-area,
.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  background-color: #fff;
  padding: 30px;
  border-radius: 0.25rem;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  color: black;
  /* border: 1px solid #dee2e6; */
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.05);
}

.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  position: relative;
}

.swiss .support .support-info .info-form .bd-r {
  border-right: 1px solid #dee2e6;
}

.swiss .support .support-info .info-form h1,
.swiss .privacy .privacy-info .info-area h1 {
  color: var(--blue);
}

.swiss .support .support-info .info-form h5,
.swiss .booking .booking-info .info-form h5,
.swiss .scholarship-app .scholarship-app-info .info-form h5 {
  font-size: 22px;
  margin: 20px 0;
  color: var(--blue);
}

.swiss .support .support-info .info-form p,
.swiss .booking .booking-info .info-form p,
.swiss .scholarship-app .scholarship-app-info .info-form p {
  font-size: 18px;
  color: #000;
}

.swiss .support .support-info .info-form ul li,
.swiss .privacy .privacy-info .info-area ul li {
  padding: 10px 0;
}

.swiss .support .support-info .info-form input:focus,
.swiss .support .support-info .info-form textarea:focus,
.swiss .booking .booking-info .info-form input:focus,
.swiss .booking .booking-info .info-form textarea:focus,
.swiss .scholarship-app .scholarship-app-info .info-form input:focus,
.swiss .scholarship-app .scholarship-app-info .info-form textarea:focus {
  box-shadow: none;
  border-color: var(--blue);
}

.swiss .support .support-info .info-form textarea,
.swiss .booking .booking-info .info-form textarea,
.swiss .scholarship-app .scholarship-app-info .info-form textarea {
  resize: none;
}

.swiss .support .support-info .info-form button,
.swiss .booking .booking-info .info-form button,
.swiss .scholarship-app .scholarship-app-info .info-form button {
  border-radius: 3px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
  font-size: 15px;
  background-color: var(--blue);
  border-color: var(--blue);
  color: #000;
}

.swiss .support .support-info .info-form button:disabled,
.swiss .booking .booking-info .info-form button:disabled,
.swiss .scholarship-app .scholarship-app-info .info-form button:disabled {
  cursor: not-allowed;
}

.swiss .support .support-info .info-form button:hover,
.swiss .support .support-info .info-form button:active,
.swiss .booking .support-info .info-form button:hover,
.swiss .booking .support-info .info-form button:active,
.swiss .scholarship-app .scholarship-app-info .info-form button:hover,
.swiss .scholarship-app .scholarship-app-info .info-form button:active {
  background-color: var(--blue);
  border-color: var(--blue);
  color: #000;
}

.swiss .support .support-info .info-form button:focus,
.swiss .support .support-info .info-form button:active:focus,
.swiss .booking .booking-info .info-form button:focus,
.swiss .booking .booking-info .info-form button:active:focus,
.swiss .scholarship-app .scholarship-app-info .info-form button:focus,
.swiss .scholarship-app .scholarship-app-info .info-form button:active:focus {
  box-shadow: none;
}

.swiss .support .spinner-border,
.swiss .booking .spinner-border,
.swiss .scholarship-app .spinner-border {
  width: 1rem;
  height: 1rem;
  color: #000;
  margin-left: 0.5rem;
}

.swiss .support .support-info .info-form p.text-swiss {
  color: var(--blue);
}

.swiss .footer {
  padding: 50px 0 20px 0;
  background-color: var(--blue);
  color: var(--white);
  padding-left: 125px;
  padding-right: 125px;
}

.swiss .footer h5 {
  text-transform: uppercase;
  margin-bottom: 1.5rem;
}

.swiss .footer h6:last-child {
  margin-bottom: 0;
  color: var(--white);
}

.swiss .footer .info {
  margin-bottom: 2.5rem;
}

.swiss .footer .info h6 {
  margin-bottom: 1rem;
  text-transform: none;
  line-height: 1.5;
  color: var(--white);
}

.swiss .footer .info h6.heading {
  color: var(--white);
  margin-bottom: 0.25rem;
}

.swiss .footer .social-links a {
  margin-right: 50px;
  color: var(--white);
}
.swiss .footer .social-links a:hover {
  color: var(--active-blue);
}

.swiss .footer .social-links a:last-child {
  margin-right: 0;
}

.footer .contact-box:first-child {
  width: 100%;
}

.footer .contact-box {
  margin-right: 3rem;
  width: 50%;
}

.footer .contact-box:last-child {
  margin-right: 0;
}

.footer .contact-box h4 {
  font-weight: bold;
  color: #fff;
}

.footer .contact-box p {
  margin-bottom: 0;
}

.swiss .support .support-header,
.swiss .privacy .privacy-header,
.swiss .booking .booking-header,
.swiss .scholarship-app .scholarship-app-header {
  background: linear-gradient(
      176deg,
      rgba(0, 0, 0, 0.75) 0%,
      rgba(0, 0, 0, 0.2) 100%
    ),
    url(./assets/images/bgImage.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 400px;
  margin-top: -65px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
}

.swiss .support .support-header h1,
.swiss .privacy .privacy-header h1,
.swiss .booking .booking-header h1,
.swiss .scholarship-app .scholarship-app-header h1 {
  font-weight: 600;
  color: var(--white);
  font-size: 3.3rem;
  margin-bottom: 0;
}

.swiss .support .support-header p,
.swiss .privacy .privacy-header p {
  color: var(--black);
  font-size: 22px;
  margin: 20px 0;
}

.swiss .support .support-info,
.swiss .privacy .privacy-info,
.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: 400px;
  background-color: var(--white);
  position: relative;
}

.swiss .booking .booking-info,
.swiss .scholarship-app .scholarship-app-info {
  height: auto;
}

.swiss .support .support-info .info-form,
.swiss .privacy .privacy-info .info-area,
.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  background-color: var(--white);
  padding: 30px;
  border-radius: 0.25rem;
  max-width: 1200px;
  width: 100%;
  position: absolute;
  top: -100px;
  left: 50%;
  transform: translateX(-50%);
  /* border: 1px solid #dee2e6; */
  box-shadow: 0 0rem 1rem rgba(0, 0, 0, 0.05);
}

.swiss .booking .booking-info .info-form,
.swiss .scholarship-app .scholarship-app-info .info-form {
  position: relative;
}

.swiss .support .support-info .info-form .bd-r {
  border-right: 1px solid #dee2e6;
}

.swiss .support .support-info .info-form h1,
.swiss .privacy .privacy-info .info-area h1 {
  color: var(--blue);
}

.swiss .support .support-info .info-form h5,
.swiss .booking .booking-info .info-form h5,
.swiss .scholarship-app .scholarship-app-info .info-form h5 {
  font-size: 22px;
  margin: 20px 0;
  color: var(--blue);
}

.swiss .support .support-info .info-form p,
.swiss .booking .booking-info .info-form p,
.swiss .scholarship-app .scholarship-app-info .info-form p {
  font-size: 18px;
  color: var(--white);
}

.swiss .support .support-info .info-form ul li,
.swiss .privacy .privacy-info .info-area ul li {
  padding: 10px 0;
}

.swiss .support .support-info .info-form p.text-swiss {
  color: var(--blue);
}

.swiss .privacy .privacy-info {
  height: auto;
}

.swiss .privacy .privacy-info .info-area {
  position: relative;
}

.swiss .privacy .privacy-info .info-area h6 {
  font-weight: bold;
  margin: 20px 0 40px 0;
}

.swiss .privacy .privacy-info .info-area a {
  color: var(--blue);
  cursor: pointer;
}

.swiss .privacy .privacy-info .info-area ul {
  list-style-type: square;
}

.swiss .privacy .privacy-info .info-area .table-contents {
  padding-left: 0;
  list-style-type: none;
}

.swiss .privacy .privacy-info .info-area .table-content-area {
  margin: 50px 0;
}

.swiss .back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 30px;
  bottom: 30px;
  z-index: 996;
  background: var(--blue);
  width: 40px;
  height: 40px;
  border-radius: 5px;
  transition: all 0.4s;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.swiss .back-to-top i {
  font-size: 28px;
  color: var(--white);
  line-height: 0;
}

.swiss .back-to-top.active {
  visibility: visible;
  opacity: 1;
}

.swiss .customBtn {
  background-color: var(--blue);
  border-color: var(--blue);
}
.swiss .customBtn:hover {
  background-color: var(--active-blue);
  border-color: var(--active-blue);
}

.modal-content {
  background-color: var(--black) !important;
}

.close {
  color: var(--white) !important;
}

.map {
  -webkit-filter: grayscale(1) invert(1);
  -moz-filter: grayscale(1) invert(1);
  -ms-filter: grayscale(1) invert(1);
  -o-filter: grayscale(1) invert(1);
  filter: grayscale(1) invert(1);
}

@media (max-width: 1500px) {
  .swiss .subscribe h1 {
    font-size: 30px;
  }
}

@media (max-width: 1400px) {
  .swiss .hero .hero-content {
    width: 100%;
  }
  .footer .contact-box {
    width: 100%;
    margin-right: 1rem;
  }
}

@media (max-width: 1300px) {
  .swiss .swiss-navbar .navbar-nav .nav-link {
    margin-right: 60px;
  }
  .swiss .subscribe .input-group .input-group-prepend .input-group-text {
    padding: 0.375rem 1rem;
    font-size: 14px;
  }
  .swiss .footer .social-links a {
    margin-right: 0;
  }
  .swiss .footer .social-links img {
    margin-right: 25px;
  }
  .swiss .courses .hero-content p br {
    display: none;
  }
}

@media (max-width: 1200px) {
  .stream .swiper-container {
    padding-left: 0;
  }
  .swiss .swiss-navbar .navbar .navbar-collapse {
    padding-left: 0;
  }
  .swiss .stream .small-text {
    margin-top: 0;
  }
  .swiss .support .support-info {
    height: auto;
  }
  .swiss .support .support-info .info-form {
    max-width: 100%;
    position: relative;
    top: 0;
    border-radius: 0;
    border: 0;
  }
  .swiss .support .support-info .info-form .bd-r {
    border-right: 0;
  }
  .swiss .courses {
    padding-top: 30px;
    text-align: center;
  }
  .swiss .courses h2 {
    max-width: 450px;
    margin: 0 auto;
  }
  #paidModal h1 {
    font-size: 35px;
  }
  #paidModal h2 {
    font-size: 32px;
  }
  #paidModal h4 {
    font-size: 30px;
  }
  .paidModalImage {
    width: 60%;
  }
  .swiss .support .support-info {
    height: auto;
  }
  .swiss .support .support-info .info-form {
    max-width: 100%;
    position: relative;
    top: 0;
    border-radius: 0;
    border: 0;
  }
  .swiss .support .support-info .info-form .bd-r {
    border-right: 0;
  }
}

@media (max-width: 991px) {
  .swiss .swiss-navbar .bg-black {
    padding: 0.5rem 2rem;
  }
  .swiss .swiss-navbar .bg-navbar {
    background-color: transparent;
    padding: 0.5rem 2rem;
  }
  .swiss .hero {
    padding-left: 75px;
  }
  .swiss .hero .hero-content h1 {
    font-size: 2.5rem;
  }
  .swiss .hero .hero-content p {
    font-size: 18px;
  }
  .swiss .courses {
    padding-left: 70px;
  }
  .swiss .stream {
    padding: 50px 0;
  }
  .swiss .stream .courses-heading h1 {
    font-size: 2.5rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
  .swiss .stream .courses-heading h3 {
    font-size: 1.75rem;
  }
  .swiss .stream .courses-heading h1,
  .swiss .stream .courses-heading h3 {
    margin-left: 70px;
  }
  .swiss .subscribe input {
    height: 3rem;
  }
  .swiss .footer {
    padding-left: 50px;
    padding-right: 50px;
  }
  .swiss .footer .social-links img {
    margin-right: 20px;
  }
  .swiss .courses h2 {
    max-width: 100%;
  }
  #paidModal h1 {
    font-size: 30px;
  }
  #paidModal h2 {
    font-size: 27px;
  }
  #paidModal h4 {
    font-size: 25px;
  }
  .paidModalImage {
    width: 80%;
  }
}

@media (max-width: 767px) {
  .swiss .hero {
    padding-left: 2rem;
  }
  .desktopOnly h1 {
    font-size: 30px;
  }
  .desktopOnlyImage {
    width: 50px;
  }
  .footer .contact-box {
    margin-bottom: 1rem;
  }
  .swiss .footer .social-links a {
    margin-right: 15px;
  }
}

@media (max-width: 768px) {
  .desktopOnlyImage {
    width: 500px;
  }
  .storeIcon {
    width: 250px;
  }
  #imgHover {
    height: 250px;
    /* object-fit: cover; */
  }
  .stream img {
    height: 250px;
  }
  .customSwiper img {
    height: 250px;
  }
}

@media (max-width: 575px) {
  .swiss .hero {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .swiss .hero .hero-content h1 {
    font-size: 2rem;
  }
  .swiss .hero .hero-content p {
    width: 100%;
    font-size: 16px;
  }
  .swiss .courses {
    padding: 0 30px;
  }
  .swiss .courses .hero-content p br {
    display: none;
  }
  .swiss .stream .courses-heading h1 {
    font-size: 2rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  .swiss .stream .courses-heading h3 {
    font-size: 1.5rem;
  }
  .swiss .stream .courses-heading h1,
  .swiss .stream .courses-heading h3 {
    margin-left: 30px;
  }
  .swiss .app,
  .swiss .subscribe {
    margin: 0;
  }
  .swiss .subscribe h1 {
    font-size: 25px;
  }
  .swiss .footer {
    padding-left: 15px;
    padding-right: 15px;
  }
  .swiss .footer h6 {
    margin-bottom: 1.5rem;
  }
  .single-course-modal.modal .modal-content .modal-body {
    padding: 0 40px 40px;
  }
  #paidModal h1 {
    font-size: 25px;
  }
  #paidModal h2 {
    font-size: 22px;
  }
  #paidModal h4 {
    font-size: 20px;
  }
  .paidModalImage {
    width: 100%;
  }
  .desktopOnlyImage {
    width: 400px;
  }
  .storeIcon {
    width: 250px;
  }
  .my-customized-Card .tab-content {
    height: 80%;
    padding: 1rem;
    overflow-y: auto;
  }
  #imgHover {
    height: 150px;
    /* object-fit: cover; */
  }
  .stream img {
    height: 150px;
  }
  .customSwiper img {
    height: 250px;
  }
}
